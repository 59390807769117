import React from 'react';

import Header from '../modules/Header';
import BasicTabs from '../modules/Tab';
import StickyFooter from '../modules/Footer';

const LoginTop = () => (
  <div>
    <Header />
    <BasicTabs />
    <StickyFooter />
  </div>
);

export default LoginTop;
