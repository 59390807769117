import * as React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ShippedCard from './shippedStatusPage/ShippedCard';

const ShippedStatus = () => {
  const userInfo = useSelector((state) => state.userInfo);
  console.log(userInfo.winningList);
  
  /*
  // 静的なプロップスの生成
  const shippedCardPropsTest1 = {
    productId: 'e8fb53ee-3a70-4193-80d4-2c423fc04953',
    productTitle: 'staticTestProductTitle',
    categoryName: 'staticTestCategory',
    lowestRankTicket: 'GOLD',
    lowestRankTicketUnit:2,
    rouletteDate: '15/05/2022 12:00:00',
    limitUnit: 20,
    participatingUnit: 4,
    ticketInfo:{
      normal:0,
      gold:4,
      premium:1
    },
    prizeRank:1,
    prizeName:"1stPrize"
  };
  const shippedCardPropsTest2 = {
    productId: 'e8fb53ee-3a70-4193-80d4-2c423fc049532',
    productTitle: 'staticTestProductTitle',
    categoryName: 'staticTestCategory',
    lowestRankTicket: 'PREMIUM',
    lowestRankTicketUnit:2,
    rouletteDate: '15/05/2022 12:00:00',
    limitUnit: 20,
    participatingUnit: 4,
    ticketInfo:{
      normal:0,
      gold:4,
      premium:1
    },
    prizeRank:2,
    prizeName:"2ndPrize"
  };
  
  const shippedCardPropsArray = [shippedCardPropsTest1, shippedCardPropsTest2];
  */
  const shippedCardPropsArray1 = userInfo.winningList.map((item)=>({
    productId: item.product_id,
    productTitle: item.product_name,
    categoryName: item.category_name,
    lowestRankTicket: item.lowest_rank,
    lowestRankTicketUnit: item.lowest_rank_ticket_unit,
    rouletteDate: item.roulette_date,
    limitUnit: item.limit_sheets,
    participatingUnit: item.amount,
    prizeRank: item.prize_info.rank,
    prizeName: item.prize_info.title,
    deliveryStatus: item.delivery_status.status,
    trackingNumber: item.delivery_status.tracking_number
  }));
  
  console.log(shippedCardPropsArray1);
  
  
  return (
    <Box sx={{ flexGrow: 1, width: '80%', mx: 'auto' }}>
      <FormGroup>
        <FormControlLabel control={<Switch />} label="Not display expired roulette" />
      </FormGroup>
      <Grid container spacing={2}>
        {shippedCardPropsArray1.map((shippedCardProps)=>(
          <Grid item xs={6} key={shippedCardProps.productId} >
            <ShippedCard {...shippedCardProps} />
          </Grid>
        ))}
      </Grid>
    </Box>
);};

export default ShippedStatus;
