import React from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from "react-redux";

import Header from '../modules/Header';
import SearchResultList from '../modules/SearchResultList';
import StickyFooter from '../modules/Footer';
import ProductListSortForms from '../modules/ProductListSortForms';


const SearchResultListPage = () => {
    console.log('test');

  
  return (
  <div>
    <Header />
    <ProductListSortForms categoryHidden />
    <SearchResultList />
    <StickyFooter />
  </div>
);};

export default SearchResultListPage;